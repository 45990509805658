@value colors: "../../../../styles/variables/colors.module.css";
@value color-blue200, color-white from colors;
@value layout: "../../../../styles/variables/layout.module.css";
@value breakpoint-s, gap-s, gap-m from layout;

.wrapper {
  display: flex;
  height: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Web */
@media (min-width: breakpoint-s) {
  .information {
    display: flex;
    flex-grow: 1;
    padding: gap-m;
    border: 1px solid color-blue200;
    border-top: none;
  }
}

/* Mobile */
@media (max-width: breakpoint-s) {
  .wrapper {
    position: relative;
    margin-right: -1rem;
    margin-left: -1rem;
  }

  .cover {
    width: 100%;
  }

  .information {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: gap-s 1rem;
    background: linear-gradient(180deg, rgba(2,11,23,0) 10%, rgba(2,11,23,1) 100%);
    color: color-white;
  }
}

@import '../../../sass/variables_new';

.widget-temporal-relative-time-with-icon {
  display: flex;
  gap: $gap-s;
  align-items: center;
  color: $color-grey600;

  svg {
    fill: $color-grey600;
  }

  @media (max-width: $breaking-point-mobile-tablet) {
    &.color-light-mobile {
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }
}

@value layout: "../../../styles/variables/layout.module.css";
@value breakpoint-s, gap-m, gap-l from layout;

@media (min-width: breakpoint-s) {
  .headlines {
    display: flex;
    gap: gap-m;
    margin: gap-m 0 gap-l;
  }

  .hero {
    flex-basis: 0;
    flex-grow: 1;
  }

  .side-items {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: gap-m;
  }

  .side-item {
    flex-basis: calc(50% - gap-m / 2);
    flex-grow: 1;
  }
}

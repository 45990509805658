@value layout: "../../../../../styles/variables/layout.module.css";
@value breakpoint-s, gap-s from layout;
@value fonts: "../../../../../styles/variables/fonts.module.css";
@value font-size-xs from fonts;
@value colors: "../../../../../styles/variables/colors.module.css";
@value color-white, color-light-grey, color-grey, color-grey600 from colors;

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: gap-s;
  justify-content: space-between;
}

.top-information {
  display: flex;
  flex-direction: column;
  gap: gap-s;
}

.top-information .excerpt {
  color: color-grey;
}

.bottom-information {
  display: flex;
  flex-direction: column;
  gap: gap-s;
  font-size: font-size-xs;
  line-height: font-size-xs;
  fill: color-grey600;
}

.bottom-information .signature {
  font-weight: bold;
  color: color-grey600;
}

.bottom-information .date-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-information .mobile-date {
  display: none;
}

.bottom-information .actions {
  display: flex;
  gap: gap-s;
}

.sr-only {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}

.placeholder {
  background-color: color-light-grey;
}

/* Size */
.size-large .top-information {
  flex-grow: 1;
  justify-content: space-evenly;
}

.size-small .excerpt {
  display: none;
}

.size-small.placeholder {
  height: 118px;
}

/* Mobile */
@media (max-width: breakpoint-s) {
  .top-information .excerpt {
    display: none;
  }

  /* Size */
  .size-small.placeholder {
    height: 108px;
  }

  .size-large.placeholder {
    height: 126px;
    background: none;
  }

  /* Variant */
  .variant-author-full-date .mobile-date {
    display: block;
  }

  .variant-author-full-date .date,
  .variant-author-full-date .signature {
    display: none;
  }

  /* Color */
  .color-light-mobile .excerpt,
  .color-light-mobile .signature {
    color: color-white;
  }

  .color-light-mobile .bottom-information {
    fill: color-white;
  }
}
